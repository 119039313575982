<template>
	<div>
		<el-row>
			<el-col>
				<el-form :inline='true'>
					<el-form-item label="关键字:">
						<el-input v-model="filters.keyWord" @input="(e) => (filters.keyWord = utils.validForbid(e))"
						          placeholder="项目编号/项目名称" clearable></el-input>
					</el-form-item>
					<!--					<el-form-item label="">-->
					<!--						<el-select v-model="filters.areaStr" placeholder="请选择省份" filterable clearable>-->
					<!--							<el-option-->
					<!--									v-for="item in areaArr"-->
					<!--									:key="item.id"-->
					<!--									:label="item.name"-->
					<!--									:value="item.code"-->
					<!--							>-->
					<!--							</el-option>-->
					<!--						</el-select>-->
					<!--					</el-form-item>-->
					<el-form-item label="">
						<el-select v-model="filters.entyType" placeholder="项目状态" clearable>
							<el-option
									v-for="item in entyTypeArr"
									:key="item.value"
									:label="item.name"
									:value="item.value"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<div class="toolbar-container">
							<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
							<!--              <el-button type="primary" @click="pretrialToFormal" >预审转正式</el-button>-->
						</div>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<!-- 列表 -->
		<el-table
				:data="tableData"
				border
				style="width: 100%"
				v-loading="listLoading"
				ref="multipleTable"
				@current-change='currentChange'
				:highlight-current-row='true'
		>
			<el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
			<!-- <el-table-column type="selection" width="45"></el-table-column> -->
			<el-table-column prop="projectNo" label="项目编号"  align="center" width="155"
			                 show-overflow-tooltip></el-table-column>
			<el-table-column prop="areaName" label="所属县（区）" width='190' align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="projectName" label="项目名称" align="center" show-overflow-tooltip>
				<template slot-scope='scope'>
					<el-button @click="viewsEntyTemplate(scope.row)" type="text">{{ scope.row.projectName }}</el-button>
				</template>
			</el-table-column>
			<el-table-column prop="ddIdNodeBizType" label="业务类型" align="center" show-overflow-tooltip width="100">
				<template slot-scope='scope'>
					<el-tag v-if="scope.row.ddIdNodeBizType === 1" type="success">图审</el-tag>
					<el-tag v-if="scope.row.ddIdNodeBizType === 10" type="danger">验收</el-tag>
					<el-tag v-if="scope.row.ddIdNodeBizType === 100" type="danger">开业检查</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="ddIdVenueNature" label="场所类型" align="center" show-overflow-tooltip width="100">
				<template slot-scope='scope'>
					<div v-for="item in ddIdVenueNature">
						<el-tag v-if="scope.row.ddIdVenueNature === item.id"  type="success">{{ item.value }}</el-tag>
					</div>
				</template>
			</el-table-column>
<!--			<el-table-column prop="SuccessPersonNumber" label="申请表" align="center" width="70">-->
<!--				<template slot-scope='scope'>-->
<!--					<el-button @click="viewsApplicationForm(scope.row)" type="text">编辑</el-button>-->
<!--				</template>-->
<!--			</el-table-column>-->
			
			<el-table-column prop="ddIdProjectStatusName" label="项目状态" align="center" width="140" show-overflow-tooltip>
				<!-- <template slot-scope='scope'>
						<el-tag v-if="scope.row.ddIdProjectStatus == 46" type="">初审意见提交完成</el-tag>
						<el-tag v-else-if="scope.row.ddIdProjectStatus == 134" type="">初审报告完成</el-tag>
						<el-tag v-else-if="scope.row.ddIdProjectStatus == 47" type="">初审报告签章完成</el-tag>
						<el-tag v-else-if="scope.row.ddIdProjectStatus == 53" type="">复核意见提交完成</el-tag>
						<el-tag v-else-if="scope.row.ddIdProjectStatus == 135" type="">复核报告完成</el-tag>
						<el-tag v-else>图审报告签章完成</el-tag>
				</template> -->
			</el-table-column>
			
			<!-- <el-table-column prop="SendTime" label="项目图纸" align="center" width="">
					<template slot-scope='scope'>
							<el-button @click="viewsEntyTemplate(scope.row)" type="text">查看</el-button>
					</template>
			</el-table-column> -->
			<!--
						<el-table-column prop="SendTime" label="项目参与人员" align="center" width="120">
								<template slot-scope='scope'>
										<el-button @click="viewsParticipants(scope.row)" type="text">查看</el-button>
								</template>
						</el-table-column>

						<el-table-column prop="SendTime" label="图审人员初审意见" align="center" width="90">
								<template slot-scope='scope'>
										<el-button @click="primaryExaminations(scope.row)" type="text">查看</el-button>
								</template>
						</el-table-column>
						<el-table-column prop="SendTime" label="初审意见 （图审报告）" align="center" width="115">
								<template slot-scope='scope'>
										<el-button @click="viewsPreliminaryReport(scope.row)" type="text">查看</el-button>
								</template>
						</el-table-column>
						<el-table-column prop="SendTime" label="图审人员复核意见" align="center" width="90">
								<template slot-scope='scope'>
										<el-button @click="primaryCheckExaminations(scope.row)" type="text">查看</el-button>
								</template>
						</el-table-column>
						<el-table-column prop="SendTime" label="复核意见(1...n次)" align="center" width="90">
								<template slot-scope='scope'>
										<el-button @click="viewsreviewReport(scope.row)" type="text">查看</el-button>
								</template>
						</el-table-column>
			-->
			<el-table-column prop="SendTime" label="项目阶段" align="center" width="65">
				<template slot-scope='scope'>
					<el-button @click="viewStageList(scope.row)" type="text">查看</el-button>
				</template>
			</el-table-column>
			<el-table-column prop="SendTime" label="终审报告" align="center" width="65">
				<template slot-scope='scope'>
					<el-button @click="viewsAuditReport(scope.row)" type="text">查看</el-button>
				</template>
			</el-table-column>
<!--			<el-table-column prop="SendTime" label="下载图纸" align="center" width="65">-->
<!--				<template slot-scope='scope'>-->
<!--					<el-button @click="downloadDrawings(scope.row)" type="text">下载</el-button>-->
<!--				</template>-->
<!--			</el-table-column>-->
<!--			<el-table-column prop="isPrequalification" label="是否预审" align="center" width="65">-->
<!--				<template slot-scope='scope'>-->
<!--					<el-tag v-if="scope.row.isPrequalification == true" type="success">是</el-tag>-->
<!--					<el-tag v-if="scope.row.isPrequalification == false" type="danger">否</el-tag>-->
<!--				</template>-->
<!--			</el-table-column>-->
<!--			<el-table-column prop="ddIdConclusionStatus" label="是否冻结" align="center" width="65">-->
<!--				<template slot-scope='scope'>-->
<!--					<el-tag v-if="scope.row.ddIdConclusionStatus == 57" type="success">是</el-tag>-->
<!--					<el-tag v-if="scope.row.ddIdConclusionStatus == 0" type="danger">否</el-tag>-->
<!--				</template>-->
<!--			</el-table-column>-->
			<!-- <el-table-column prop="SendCrowd" label="图纸数量" align="center" show-overflow-tooltip></el-table-column> -->
			<el-table-column prop="coefficient" label="项目系数" align="center" width="60"
			                 show-overflow-tooltip></el-table-column>
			<el-table-column prop="SendTime" label="留言" align="center" width="60">
				<template slot-scope='scope'>
					<el-button @click="viewsLeaveMessage(scope.row)" type="text">查看</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!--    <FireApplicationForm :projectRegister.sync="projectRegister" @update:projectRegister="updateDialogVisibleData" :neeData="needData"/>-->
		<!--  消防审查申请表-->
		<el-dialog
				title="项目登记"
				:visible.sync="projectRegisterDialog"
				v-model="projectRegisterDialog"
				:close-on-click-modal="false"
				width='1200px'
		>
			<WordTable :isDisabled="false" :projectId="needData ? needData.id : 0"
			           :needData="needData" @closeThisDialog="closeThisDialog"></WordTable>
		</el-dialog>
		<!--  预审转正式-->
		<el-dialog
				:title="'预审转正式'"
				:visible.sync="addNewEntryDialogToForm"
				v-model="addNewEntryDialogToForm"
				:close-on-click-modal="false"
				width='50%'
		>
			<el-form :model="addNewEntryForm" :rules="addNewEntryRules" ref="addNewEntryRef" label-width="160px"
			         style='min-width:800px'>
				<el-form-item label="合同编号:" prop="code">
					<el-input v-model='addNewEntryForm.code' clearable disabled></el-input>
				</el-form-item>
				<el-form-item label="项目名称:" prop="entryName">
					<el-input v-model='addNewEntryForm.entryName' clearable></el-input>
				</el-form-item>
				<!-- <el-form-item label="项目类型:" prop="entryType">
						<el-select v-model="addNewEntryForm.entryType" clearable>
								<el-option
										v-for="item in entryTypeArr"
										:key="item.value"
										:label="item.name"
										:value="item.value"
								>
								</el-option>
						</el-select>
						<span v-if="addNewEntryForm.entryType == 31">
								法定审结期限：15天
						</span>
						<span v-if="addNewEntryForm.entryType == 32 || addNewEntryForm.entryType == 33">
								法定审结期限：30天
						</span>
				</el-form-item> -->
				<!--        <el-form-item label="是否预审:" prop="isAntecedentTrial">-->
				<!--          <el-radio v-model="addNewEntryForm.isAntecedentTrial" label="false">否</el-radio>-->
				<!--          <el-radio v-model="addNewEntryForm.isAntecedentTrial" label="true">是</el-radio>-->
				<!--          <div style="margin-top: -15px;font-size: 12px;color: #888;">若选择为预审项目，则该项目的图审服务不计费</div>-->
				<!--        </el-form-item>-->
				<el-form-item label="项目分类:" prop="projectType">
					<el-select v-model="addNewEntryForm.projectType" clearable>
						<el-option
								v-for="item in projectTypeArr"
								:key="item.value"
								:label="item.name"
								:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="工程分类:" prop="engineeringType">
					<el-select v-model="addNewEntryForm.engineeringType" clearable>
						<el-option
								v-for="item in engineeringTypeArr"
								:key="item.value"
								:label="item.name"
								:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="审查时限:" prop="firstReportOverdueNums">
					<el-input class="areaSty" v-model='addNewEntryForm.firstReportOverdueNums' clearable></el-input>
					天 ( 三位图审工程师审图截止期限 )
				</el-form-item>
				<el-form-item label="复核时限:" prop="reviewReportOverdueNums">
					<el-input class="areaSty" v-model='addNewEntryForm.reviewReportOverdueNums' clearable></el-input>
					天 ( 技术部工程师意见汇总截止期限 )
				</el-form-item>
				<el-form-item label="审查报告编号(合格):" prop="customReportNo">
					<el-input v-model='addNewEntryForm.customReportNo' clearable></el-input>
				</el-form-item>
				<el-form-item label="审查报告编号(不合格):" prop="customFailReportNo">
					<el-input v-model='editEntryForm.customFailReportNo' clearable></el-input>
				</el-form-item>
				<el-form-item class="spacilTimer" label="承诺办结日期:" prop="settleTimer">
					<el-date-picker
							v-model="addNewEntryForm.settleTimer"
							type="date"
							placeholder="选择日期"
					>
					</el-date-picker>
					( 项目终审截止时间 )
				</el-form-item>
				<!-- <el-form-item label="政府承诺制审结期限:">
						<el-input v-model='addNewEntryForm.endTimer' clearable class="timerSty" :disabled="addNewEntryForm.noEndTimer"></el-input>
						天
						<el-checkbox v-model="addNewEntryForm.noEndTimer" class="noTimerSty">政府无承诺时间</el-checkbox>
				</el-form-item> -->
				<el-form-item label="图审面积:" prop="areaMeasure">
					<el-input class="areaSty" v-model='addNewEntryForm.areaMeasure' clearable></el-input>
					平方米
				</el-form-item>
				<el-form-item class="spacilTimer" label="项目说明:">
					<el-input v-model='addNewEntryForm.remark' type="textarea" :rows="3"></el-input>
					<div style="font-size: 12px;color: #888;">根据当地建设局要求表述</div>
				</el-form-item>
				<el-form-item label="项目联系人:" prop="entryContacts">
					<el-input v-model='addNewEntryForm.entryContacts' clearable></el-input>
				</el-form-item>
				<el-form-item label="手机号码:" prop="entryPhone">
					<el-input v-model='addNewEntryForm.entryPhone' clearable></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="addNewEntryDialogToForm = false">取消</el-button>
				<el-button type="primary" @click="addNewEntrySubmit" :loading="addNewEntryLoading">确认</el-button>
			</div>
		</el-dialog>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar">
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pages.pageIndex"
					:page-sizes="pages.pageArr"
					:page-size="pages.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="pages.dataCount">
			</el-pagination>
		</el-col>
		<!-- 申请援助 -->
		<el-dialog
				title="申请援助"
				:visible.sync="applyForSendSMSDialog"
				v-model="applyForSendSMSDialog"
				:close-on-click-modal="false"
				width='50%'
		>
			<div class="projectName projectSelect">项目名称：{{ needData.projectName }}</div>
			<div class="projectSelect">
				<el-radio v-model="applyRadio" label="105">同时期任务重叠冲突，申请援助</el-radio>
			</div>
			<div class="projectSelect">
				<el-radio v-model="applyRadio" label="106">项目特殊，无法胜任，申请援助</el-radio>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="applyForSendSMSDialog = false">取消</el-button>
				<el-button type="primary" @click="applyForSendSMSubmit" :loading="applyForSendSMLoading">确定</el-button>
			</div>
		</el-dialog>
		<!-- 项目参与人员 -->
		<el-dialog
				:title="ParticipantsDialogName"
				:visible.sync="projectDelayDialog?projectDelayDialog:ParticipantsDialog"
				v-model="projectDelayDialog?projectDelayDialog:ParticipantsDialog"
				:close-on-click-modal="false"
				@close="colsedProjectDelay"
				width='70%'
		>
			<el-table
					:data="ParticipantsData"
					border
					style="width: 100%"
					v-loading="ParticipantsLoading"
					ref="ProjectDelayRef"
					:highlight-current-row='true'
					:cell-style="rowClass"
					@current-change="handleDelay"
			>
				<el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
				<el-table-column prop="participationTypeName" width='140' label="类型" align="center"
				                 show-overflow-tooltip></el-table-column>
				<el-table-column prop="name" label="姓名" align="center" width='' show-overflow-tooltip>
					<template slot-scope='scope'>
						{{ scope.row.name }}
					</template>
				</el-table-column>
				
				<el-table-column prop="applyStatusName" label="状态" width='200' align="center" show-overflow-tooltip>
					<template #default="scope">
						{{scope.row.participationTypeName==='AI图审工程师'&&parseNum !== 'NaN%'&&parseNum !== undefined?(scope.row.applyStatusName+'('+parseNum+')'):scope.row.applyStatusName}}
					</template>
				</el-table-column>
				<el-table-column prop="signStatus" label="签署状态" width='160' align="center" show-overflow-tooltip></el-table-column>
				<el-table-column prop="overdueDate" width='160' label="到期时间(晚)" align="center" show-overflow-tooltip>
					<template slot-scope="scope">
						<template  v-if="!scope.row.completeDate && projectDelayDialog&&(scope.row.expertType===62||scope.row.expertType===63||scope.row.expertType===101)">
							<el-input v-model="scope.row.overdueDate" border="false"  :placeholder="scope.row.overdueDate" clearable @blur="addProjectDelay"></el-input>
						</template>
						<template  v-else>
							{{ scope.row.overdueDate }}
						</template>
					</template>
				</el-table-column>
				<el-table-column prop="completeDate" label="完成时间" width='170' align="center" show-overflow-tooltip></el-table-column>
				<el-table-column prop="phoneNumber" label="手机号" width='150' align="center" show-overflow-tooltip></el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer">
				<el-button v-if="projectDelayDialog" @click.native="projectDelayDialog = false">取消</el-button>
				<el-button v-else @click.native="ParticipantsDialog = false">取消</el-button>
			</div>
		</el-dialog>
		<!-- 复核报告 -->
		<el-dialog
				:title="reportORidea ? '复核报告' : '复核意见'"
				:visible.sync="reviewReportDialog"
				v-model="reviewReportDialog"
				:close-on-click-modal="false"
				width='50%'
		>
			<ReviewReport :projectId="preliminaryProjectId" :reportORidea="reportORidea" @reviewReportClick="reviewReportClick"></ReviewReport>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="reviewReportDialog = false">取消</el-button>
			</div>
		</el-dialog>
		<!-- 留言 -->
		<el-dialog
				title="留言"
				:visible.sync="leaveMessageDialog"
				v-model="leaveMessageDialog"
				:close-on-click-modal="false"
				width='50%'
		>
			<div class="projectTitle">项目名称：{{leaveMessageProjectName}}</div>
			<div v-for="(item,index) in leaveMessageArr" :key="index">
				<div>{{item.createUserName}}<span class="titleTimer">{{formatCreateTime(item.createTime)}}</span></div>
				<div style="margin: 5px 0px;">{{item.content}}</div>
				<div class="replyBtn" @click="replyMessageFun(item)">回复</div>
				<div class="replyMessage" v-for="(o,i) in item.reply" :key="i">
					<div>{{o.createUserName}}<span class="titleTimer">{{formatCreateTime(o.createTime)}}</span></div>
					<div><span v-if="o.replyUserName"> 回复 @{{o.replyUserName}} </span>{{o.content}}</div>
					<!-- <div v-for="(n,a) in o.reply" :key="a">{{n.createUserName}} 回复 @{{o.createUserName}} {{n.content}}</div> -->
					<div class="replyBtn" @click="replyMessageFun(o)">回复</div>
				</div>
			</div>
			<el-input type="textarea" style="margin-top:15px;" :rows="3" placeholder="请输入留言内容" v-model="leaveMessageContent">
			</el-input>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="leaveMessageDialog = false">取消</el-button>
				<el-button type="primary" @click="leaveMessageSubmit" :loading="leaveMessageLoading">发表留言</el-button>
			</div>
		</el-dialog>
		<!-- 回复留言 -->
		<el-dialog
				title="回复留言"
				:visible.sync="replyPerMessageDialog"
				v-model="replyPerMessageDialog"
				:close-on-click-modal="false"
				width='50%'
		>
			<el-input type="textarea" style="margin-top:15px;" :rows="3" :placeholder="textareaStr" v-model="replyPerMessageContent"></el-input>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="replyPerMessageDialog = false">取消</el-button>
				<el-button type="primary" @click="replyPerMessageSubmit" :loading="replyPerMessageLoading">发表留言</el-button>
			</div>
		</el-dialog>
		<!-- 审查意见 -->
		<el-dialog
				title="审查意见"
				:visible.sync="examinationsDialog"
				v-model="examinationsDialog"
				:close-on-click-modal="false"
				:destroy-on-close="true"
				width='70%'
		>
			<Examinations :projectId="componentProjectId" :isFirst="isFirst" :taskId="taskId"></Examinations>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="examinationsDialog = false">取消</el-button>
			</div>
		</el-dialog>
		<!-- 报告 -->
		<el-dialog
				title="报告"
				:visible.sync="preliminaryReportDialog"
				v-model="preliminaryReportDialog"
				:close-on-click-modal="false"
				:destroy-on-close="true"
				width='50%'
		>
			<PreliminaryReport :projectId="preliminaryProjectId"></PreliminaryReport>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="preliminaryReportDialog = false">关闭</el-button>
				<!-- <el-button type="primary" @click="preliminaryReportSubmit" :loading="preliminaryReportLoading">发起签章</el-button> -->
			</div>
		</el-dialog>
		<!-- 图审报告 -->
		<el-dialog
				title="终审报告"
				:visible.sync="dialogReportVisible"
				v-model="dialogReportVisible"
				:close-on-click-modal="false"
				:destroy-on-close="true"
				width='50%'
		>
			<AuditReportComponent :projectId="preliminaryProjectId"></AuditReportComponent>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="dialogReportVisible = false">关闭</el-button>
			</div>
		</el-dialog>
		<!-- 查看 -->
		<el-dialog
				title="查看"
				:visible.sync="viewsApplicationDialog"
				v-model="viewsApplicationDialog"
				:close-on-click-modal="false"
				:width='viewsApplicationWidth'
				:destroy-on-close="true"
				class="viewProject"
		>
			<ProjectView @activeChange="activeChange" :needData="needData"></ProjectView>
		</el-dialog>
		
		
		<el-dialog
				title="修改项目"
				:visible.sync="addNewEntryDialog"
				v-model="addNewEntryDialog"
				:close-on-click-modal="false"
				width='60%'
		>
			<el-form :model="editEntryForm" :rules="editEntryRules" ref="addNewEntryRef" label-width="160px" style='min-width:200px'>
				
				<el-form-item class="spacilTimer" label="项目说明:">
					<el-input v-model='editEntryForm.remark' type="textarea" :rows="4"></el-input>
					<div style="font-size: 12px;color: #888;">根据当地建设局要求表述</div>
				</el-form-item>
				<el-form-item label="建设单位:" prop="customReportNo">
					<el-input v-model='editEntryForm.contractor' clearable></el-input>
				</el-form-item>
				<el-form-item label="工程地址:" prop="customReportNo">
					<el-input v-model='editEntryForm.address' clearable></el-input>
				</el-form-item>
				<el-form-item label="审查报告编号(合格):" prop="customReportNo">
					<el-input v-model='editEntryForm.customReportNo' clearable></el-input>
				</el-form-item>
				<el-form-item label="审查报告编号(不合格):" prop="customFailReportNo">
					<el-input v-model='editEntryForm.customFailReportNo' clearable></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="addNewEntryDialog = false">取消</el-button>
				<el-button type="primary" @click="editEntrySubmit" :loading="addNewEntryLoading">确认</el-button>
			</div>
		</el-dialog>
		<!--修改项目系数-->
		<el-dialog
				title="修改项目"
				:visible.sync="editProjectContactDialog"
				v-model="editProjectContactDialog"
				:close-on-click-modal="false"
				width='60%'
		>
			<el-form :model="editContactEntryForm" :rules="editContactEntryFormRules" ref="addNewContactEntryRef" label-width="160px"
			         style='min-width:200px'>
				<el-form-item label="项目系数" prop="coefficient">
					<el-input v-model='editContactEntryForm.coefficient' clearable></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="editProjectContactDialog = false">取消</el-button>
				<el-button type="primary" @click="editContactEntrySubmit" :loading="addNewEntryLoading">确认</el-button>
			</div>
		</el-dialog>
		
		<el-dialog
				title="重新审图"
				:visible.sync="resetDrawingReviewDialog"
				v-model="resetDrawingReviewDialog"
				:close-on-click-modal="false"
				width='60%'
		>
			<el-form :model="resetDrawingReviewForm" :rules="resetDrawingReviewRules" ref="resetDrawingReviewRef" label-width="160px" style='min-width:200px'>
				
				<el-form-item  label="项目名称:">
					<div style="font-size: 12px;color: #888;">{{resetDrawingReviewForm.projectName}}</div>
				</el-form-item>
				
				<el-form-item class="spacilTimer" label="审图到期时间(晚):" prop="overdueDate">
					<el-date-picker
							v-model="resetDrawingReviewForm.overdueDate"
							type="date"
							placeholder="选择日期"
							value-format="yyyy-MM-dd"
					>
					</el-date-picker>
				</el-form-item>
			
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="resetDrawingReviewDialog = false">取消</el-button>
				<el-button type="primary" @click="resetDrawingReviewSubmit" :loading="resetDrawingReviewLoading">确认</el-button>
			</div>
		</el-dialog>
		
		<!-- 冻结状态 -->
		<el-dialog
				title="更改冻结状态"
				:visible.sync="freezeStatusDialog"
				v-model="freezeStatusDialog"
				:close-on-click-modal="false"
		>
			<el-form :model="freezeStatusForm"  ref="freezeStatusRef" label-width="150px" >
				<el-form-item label="变更冻结状态:">
					<el-radio-group v-model="freezeStatusForm.freezeStatus">
						<el-radio :label="57">冻结</el-radio>
						<el-radio :label="0">解冻</el-radio>
					</el-radio-group>
				</el-form-item>
			
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="freezeStatusDialog = false">取消</el-button>
				<el-button type="primary" @click="freezeStatusSubmit" :loading="freezeStatusLoading">确认</el-button>
			</div>
		</el-dialog>
		<!-- 修改技术部专家 -->
		<el-dialog
				title="修改技术部专家"
				:visible.sync="techExpertDialog"
				v-model="techExpertDialog"
				:close-on-click-modal="false"
		>
			<el-form :model="techExpertForm" ref="techExpertRef" label-width="150px" :rules="techExpertRules"  >
				<el-form-item label="修改技术部专家:">
					<el-select placeholder="请选择" style="width: 300px;" prop="techExpertForm.assignedUserId" v-model="techExpertForm.assignedUserId" filterable>
						<el-option v-for="item in techExpertData" :key="item.userInfoId" :label="item.userName"
						           :value="item.userInfoId"></el-option>
					</el-select>
				</el-form-item>
			
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="techExpertDialog = false">取消</el-button>
				<el-button type="primary" @click="techExpertSubmit" :loading="techExpertLoading">确认</el-button>
			</div>
		</el-dialog>
		
		<!-- 项目阶段明细 -->
		<el-dialog
				title="项目阶段明细"
				:visible.sync="stageDialog"
				v-model="stageDialog"
				:close-on-click-modal="false"
				width='70%'
		>
			<el-table
					:data="stageData"
					border
					style="width: 100%"
					v-loading="stageLoading"
					ref="multipleTable"
					:highlight-current-row='true'
					:cell-style="rowClass"
			>
				<el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
				<el-table-column prop="stageName"  label="阶段名称" align="center" show-overflow-tooltip></el-table-column>
				<el-table-column prop="isPrequalification" label="是否预审" align="center" width="90">
					<template slot-scope='scope'>
						<el-tag v-if="scope.row.isPrequalification == true" type="success">是</el-tag>
						<el-tag v-if="scope.row.isPrequalification == false" type="danger">否</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="SendTime" label="参与人员" align="center" width="120">
					<template slot-scope='scope'>
						<el-button @click="viewsProjectStageUserInfo(needData.id,scope.row)" type="text">查看</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="assignUploadTime" width='160' label="上传资料时间" align="center" show-overflow-tooltip>
					<template slot-scope='scope'>
						{{scope.row.assignUploadTime ? scope.row.assignUploadTime.substr(0,10) : "" }}
					</template>
				</el-table-column>
				
				<el-table-column prop="assignUploadNodeId" label="项目图纸" align="center" width="90">
					<template slot-scope='scope'>
						<el-button @click="showProjectFile(needData.id,scope.row.assignUploadNodeId)" type="text">查看</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="assignOpinionTime" width='160' label="意见提交时间" align="center" show-overflow-tooltip>
					<template slot-scope='scope'>
						{{scope.row.assignOpinionTime ? scope.row.assignOpinionTime.substr(0,10) : "" }}
					</template>
				</el-table-column>
				
				<el-table-column prop="assignOpinionNodeId" label="审查意见" align="center" width="90">
					<template slot-scope='scope'>
						<el-button v-if="scope.row.assignOpinionNodeId" @click="checkOpinonView(scope.row)" type="text">查看</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="assignOpinionSummaryTime" width='160' label="意见汇总时间" align="center" show-overflow-tooltip>
					<template slot-scope='scope'>
						{{scope.row.assignOpinionSummaryTime ? scope.row.assignOpinionSummaryTime.substr(0,10) : "" }}
					</template>
				</el-table-column>
				
				<el-table-column prop="reportUrl" label="查看报告" align="center" width="180">
					<template slot-scope='scope'>
						<el-button v-if="scope.row.reportUrl" @click="showReportFile(scope.row.reportUrl)" type="text">Word报告</el-button>
						<el-button v-if="scope.row.reportSignUrl" @click="showReportFile(scope.row.reportSignUrl)" type="text">签章报告</el-button>
					
					</template>
				
				</el-table-column>
			
			</el-table>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="stageDialog = false">取消</el-button>
			</div>
		</el-dialog>
		
		<!-- 查看审查意见 -->
		<el-dialog
				title="审查意见"
				:visible.sync="showOpinionDialog"
				v-model="showOpinionDialog"
				:close-on-click-modal="false"
				:destroy-on-close="true"
				width='80%'
		>
			<ShowOpinionComponent :aiDomainUrl = "aiDomainUrl" :projectId="showOpinionProjectId" :flowNodeId="showOpinionFlowNodeId" ></ShowOpinionComponent>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="showOpinionDialog = false">取消</el-button>
			</div>
		</el-dialog>
		
		<!-- 查看项目图纸 -->
		<el-dialog
				title="项目图纸"
				:visible.sync="projectFileDialog"
				v-model="projectFileDialog"
				:close-on-click-modal="false"
				:destroy-on-close="true"
				width='60%'
		>
			<div style="margin: 0 auto;">
				<el-tree
						:data="projectFileData"
						node-key="id"
						default-expand-all
						:props="projectFileProps"
						style="max-height: 450px;overflow-y: auto;"
				>
				</el-tree>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="projectFileDialog = false">取消</el-button>
			</div>
		</el-dialog>
		
		<!-- 亮灯提醒 -->
		<!--        <div class="specialReminder" v-if="isSpecialReminder && isShowReminder">-->
		<!--            <div class="reminderBtn">-->
		<!--                <div style="cursor: pointer;" @click="minimizeReminder">—</div>-->
		<!--            </div>-->
		<!--            <div style="text-align: center;font-size: 16px;">倒计时</div>-->
		<!--            <div class="projectPra">-->
		<!--                <div class="projectBox">-->
		<!--                    <div class="projectList" v-for="(item,index) in projectList" :key="index">距<span class="projectName">{{item.projectName}}</span>项目承诺办结日期<span class="projectDay">{{item.projectPeriodExpireDay}}天</span></div>-->
		<!--                </div>-->
		<!--            </div>-->
		<!--        </div>-->
		<div class="miniBox" @click="maxsizeReminder" v-if="!isSpecialReminder && isShowReminder">
			<div class="miniNodes">
			
			</div>
		</div>
	</div>
</template>

<script>
import util from '../../../util/date'
import Toolbar from '../../components/Toolbar'
import { getButtonList } from '../../promissionRouter'
import {
	getCheckingProjectList,
	conclusionProject,
	applyAssistance,
	getProjectMessageByProjectId,
	getProjectParticipants,
	addProjectMessage,
	getAreaDictionaryList,
	getProjectConclusionExpire,
	signatureProject,
	updateProjectInfo,
	resetDrawingReviewApi,
	getStageListByProjectId,
	getProjectFileInfoByFlowNodeId,
	convertFormalProject,
	changeTaskOverdueDate,
	changeAssignedUserIdByTechExpert,
	aiDoLogin,
	getByNamespace,
	getProjectApplicationByProjectId,
	updateProjectApplication, getProjectList
} from '@/api/api'
import ProjectView from '../drProjectManager/projectView.vue'
import Examinations from './examinationsComponent.vue'
import PreliminaryReport from './preliminaryReportComponent.vue'
import AuditReportComponent from './auditReportComponent'
import ReviewReport from './reviewReportComponent.vue'
import ShowOpinionComponent from './showOpinionComponent'
import WordTable from '@/views/drProjectManager/wordTable.vue'
import { isDigit, isValidContactNumber, validPhone } from '../../../util/validate'
import axios from 'axios';


export default {
	components: {
		Toolbar,
		ProjectView,
		Examinations,
		PreliminaryReport,
		ReviewReport,
		AuditReportComponent,
		ShowOpinionComponent,
		WordTable
	},
	data () {
		//验证手机号
		let isPhoneNumberNum = (rule, value, callback) => {
			if (!validPhone(value)) {
				return callback(new Error("请输入正确的手机号"));
			} else {
				callback();
			}
		};
		return {
			ddIdVenueNature:[],
			needdDataDelay:[],
			projectDelayContent:'',
			// viewRow:{},
			addNewEntryDialogToForm:false,
			engineeringTypeArr: [
				{ name: "特殊建设工程", value: 157 },
				{ name: "其他建设工程", value: 158 },
			],
			projectTypeArr: [
				{ name: "新建项目", value: 31 },
				{ name: "扩建项目", value: 32 },
				{ name: "改造项目", value: 33 },
			],
			addNewEntryRules: {
				code: [{ required: true, message: '请输入合同编号', trigger: 'blur' }],
				entryName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
				entryType: [{ required: true, message: '请选择项目类型', trigger: 'blur' }],
				endTimer: [{ message: '请输入政府承诺制审结期限', trigger: 'blur' }],
				areaMeasure: [{ required: true, message: '请输入图审面积', trigger: 'blur' }],
				entryContacts: [{ required: true, message: '请输入项目联系人', trigger: 'blur' }],
				entryPhone: [
					{ required: true, message: '请输入手机号码', trigger: 'blur' },
					{ validator: isPhoneNumberNum },
				],
				// 20230208新加
				// isAntecedentTrial: [{ required: true, message: '请选择是否预审', trigger: 'blur' }],
				projectType: [{ required: true, message: '请选择项目分类', trigger: 'blur' }],
				engineeringType: [{ required: true, message: '请选择工程分类', trigger: 'blur' }],
				settleTimer: [{ required: true, message: '请选择办结日期', trigger: 'blur' }],
				firstReportOverdueNums: [
					{ required: true, message: '请输入审查时限', trigger: 'blur' },
					{ validator: isDigit },
				],
				reviewReportOverdueNums: [
					{ required: true, message: '请输入复核时限', trigger: 'blur' },
					{ validator: isDigit },
				],
			},
			addNewEntryForm: {
				code: "",
				entryName: "",
				entryType: "",
				endTimer: "",
				noEndTimer: false,
				areaMeasure: "",
				remark: "",
				entryContacts: "",
				entryPhone: "",
				// 20230208新加
				// isAntecedentTrial: "false",
				projectType: "",
				engineeringType: "",
				settleTimer: "",
				customReportNo: "",
				firstReportOverdueNums: 1,
				reviewReportOverdueNums: 1,
			},
			projectRegisterDialog: false,
			utils: '',
			buttonList: [],
			needData: '',
			filters: {
				keyWord: '',
				areaStr: '',
				entyType: '45,46,52,53,140,141',
				entyStatus:'35,50',
			},
			smsTemplateArr: [],
			pushTemplateArr: [],
			areaArr: [
				{ name: '上海', value: 0 },
				{ name: '浙江', value: 1 },
				{ name: '安徽', value: 2 },
			],
			entyTypeArr: [
				{ name: '未审结', value: '45,46,52,53,140,141' },
				{ name: '未完成登记', value: '35,50' },
				{ name: '退回整改', value: '10' },
				{ name: '待提交', value: '0' },
				{ name: '已审结', value: '55' },
			],
			sendCrowdPage: {
				DataCount: 10,
			},
			sendCrowdArr: [],
			tableData: [],
			listLoading: false,
			//关于分页的obj
			pages: {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			// 申请援助
			applyForSendSMSDialog: false,
			applyForSendSMLoading: false,
			applyRadio: '',
			// 项目参与人员
			ParticipantsDialogName: '项目参与人员',
			ParticipantsDialog: false,
			ParticipantsLoading: false,
			ParticipantsData: [],
			// 复核报告/意见
			reportORidea: true,
			reviewReportDialog: false,
			reviewTableLoading: false,
			// reviewReportData: [],
			// reviewReportId: '',
			taskId: '',
			// 留言
			leaveMessageDialog: false,
			leaveMessageLoading: false,
			leaveMessageProjectName: '',
			replyId: 0,
			leaveMessageArr: [],
			leaveMessageContent: '',
			// 回复留言
			replyPerMessageDialog: false,
			replyPerMessageLoading: false,
			replyPerMessageContent: '',
			textareaStr: '请输入回复内容',
			// 审查意见
			examinationsDialog: false,
			componentProjectId: '',
			isFirst: '',
			// 初审报告
			preliminaryReportDialog: false,
			preliminaryReportLoading: false,
			preliminaryProjectId: '',
			isQualified: [],
			dialogReportVisible: false,
			// 项目查看
			viewsApplicationDialog: false,
			viewsApplicationWidth: '1200px',
			// 亮灯提醒
			isSpecialReminder: true,
			isShowReminder: false,
			projectList: [],
			//项目系数编辑
			editProjectContactDialog:false,
			// 项目编辑
			addNewEntryDialog: false,
			addNewEntryLoading: false,
			editEntryForm: {
				remark: '',
				address:undefined,
				contractor:undefined,
				customFailReportNo:undefined,
				customReportNo:undefined,
				id:undefined,
			},
			editContactEntryForm: {
				coefficient:''
			},
			editEntryRules: {
				remark: [{ required: true, message: '请输入合同编号', trigger: 'blur' }],
			},
			editContactEntryFormRules: {
				coefficient: [{ required: true, message: '请输入大于等于0的有效数字，且小数点后只保留两位的有效数字', trigger: 'blur' }, { validator: isValidContactNumber }],
			},
			// 重新审图
			resetDrawingReviewDialog: false,
			resetDrawingReviewLoading: false,
			resetDrawingReviewForm: {
				overdueDate: '',
				projectName: '',
			},
			resetDrawingReviewRules: {
				overdueDate: [{ required: true, message: '请输入审图过期时间', trigger: 'blur' },{}],
			},
			// 阶段明细
			stageDialog: false,
			stageLoading: false,
			stageData: [],
			// 显示图审报告
			showOpinionDialog: false,
			showOpinionProjectId: '',
			aiDomainUrl:undefined,
			showOpinionFlowNodeId: '',
			// 查看图纸
			projectFileDialog: false,
			projectFileLoading: false,
			projectFileData: [],
			projectFileProps: {
				children: 'childFiles',
				label: 'fileName',
			},
			// 冻结状态
			freezeStatusDialog: false,
			freezeStatusLoading: false,
			freezeStatusForm:{
				freezeStatus:0
			},
			projectDelayDialog:false,
			// 修改技术部专家
			techExpertData: [{"userInfoId":30,"userName":"冷启贞-总部-技术部-工程师"},{"userInfoId":31,"userName":"刘金刚-总部-技术部-工程师"},{"userInfoId":238,"userName":"李红蕾-总部-技术部-工程师"},{"userInfoId":239,"userName":"郭营-总部-技术部-工程师"},{"userInfoId":244,"userName":"杨健鹏-总部-技术部-工程师"},{"userInfoId":252,"userName":"庞建军-总部-技术部-工程师"},{"userInfoId":312,"userName":"李宝萍-总部-技术部-工程师"}],
			techExpertDialog: false,
			techExpertLoading: false,
			techExpertForm: {
				assignedUserId: undefined,
			},
			techExpertRules:{
				assignedUserId: [{ required: true, message: '请选择专家', trigger: 'blur' },{}]
			},
			domain:undefined,
			aiToken:undefined,
			parseNum:undefined,
		}
	},
	methods: {
		async getNamespaceArr(){
			let params = {
				namespace:"BizSevice.DrProject.VenueNature",
			}
			const res = await getByNamespace(params)
			if (res.data.success){
				this.ddIdVenueNature = res.data.response
			}
		},
		// 预审转正式
		pretrialToFormal () {
			if (this.needData) {
				this.addNewEntryDialogToForm = true
				this.addNewEntryLoading = false
				this.addNewEntryForm = {
					id:this.needData.id,
					isPrequalification:this.needData.isPrequalification,
					projectCompany:this.needData.projectCompany,
					contractId:this.needData.contractId,
					
					
					code: this.needData.contractNo,
					entryName: this.needData.projectName,
					entryType: this.needData.ddIdProjectType,
					endTimer: this.needData.projectGovPeriodDay,
					noEndTimer: !this.needData.isGovPromise,
					areaMeasure: this.needData.projectArea,
					remark: this.needData.projectNotes,
					entryContacts: this.needData.projectContacts,
					entryPhone: this.needData.projectPhone,
					// 20230208新加
					// isAntecedentTrial: JSON.stringify(this.needData.isPrequalification),
					projectType: this.needData.ddIdProjectType,
					engineeringType: this.needData.ddIdEngineeringType,
					settleTimer: this.needData.projectPeriod,
					customReportNo: this.needData.customReportNo,
					customFailReportNo:this.needData.customFailReportNo,
					coefficient:this.needData.coefficient,
					firstReportOverdueNums: this.needData.firstReportOverdueNums,
					reviewReportOverdueNums: this.needData.reviewReportOverdueNums
				}
			} else {
				this.$message.warning("请选择需要修改项！")
			}
		},
		// 提交预审转正式
		addNewEntrySubmit () {
			this.$refs.addNewEntryRef.validate((valid) => {
				if (valid) {
					this.addNewEntryLoading = true;
					const params = {
						contractId: this.addNewEntryForm.contractId,
						customReportNo: this.addNewEntryForm.customReportNo,
						customFailReportNo: this.addNewEntryForm.customFailReportNo,
						coefficient:this.addNewEntryForm.coefficient,
						ddIdEngineeringType: this.addNewEntryForm.engineeringType,
						ddIdProjectType: this.addNewEntryForm.projectType,
						firstReportOverdueNums: parseInt(this.addNewEntryForm.firstReportOverdueNums),
						id: this.addNewEntryForm.id,
						isGovPromise: this.addNewEntryForm.noEndTimer,
						isPrequalification: this.addNewEntryForm.isPrequalification,
						projectArea: this.addNewEntryForm.areaMeasure,
						projectCompany: this.addNewEntryForm.projectCompany,
						projectContacts: this.addNewEntryForm.entryContacts,
						projectGovPeriodDay: this.addNewEntryForm.endTimer,
						projectName: this.addNewEntryForm.entryName,
						// ddIdProjectType: this.addNewEntryForm.entryType,
						
						// isGovPromise: !this.addNewEntryForm.noEndTimer,
						projectNotes: this.addNewEntryForm.remark,
						projectPeriod: this.addNewEntryForm.settleTimer,
						projectPhone: this.addNewEntryForm.entryPhone,
						// 20230208新加
						// isPrequalification: this.addNewEntryForm.isAntecedentTrial,
						reviewReportOverdueNums: parseInt(this.addNewEntryForm.reviewReportOverdueNums),
						
					}
					// console.log(params)
					
					// params.contractId = this.currentContract.id;
					convertFormalProject(params).then(res => {
						this.addNewEntryLoading = false;
						this.addNewEntryDialogToForm = false;
						const result = res.data
						if (result.success) {
							this.$message.success(result.message)
							this.getDataList();
						} else {
							this.$message.warning(result.message)
						}
					})
				}
			})
		},
		// 关闭项目登记
		closeThisDialog () {
			this.projectRegisterDialog = false
		},
		// 消防设计审查申请表查看
		viewsApplicationForm (row) {
			this.needData = row
			this.projectRegisterDialog = true
		},
		callFunction (item) {
			this[item.func].apply(this, item)
		},
		activeChange (val) {
			if (val == '2') {
				this.viewsApplicationWidth = '1200px'
			}
			else if (val == '4'){
				this.viewsApplicationWidth =  '1200px'
			}
			else {
				this.viewsApplicationWidth = '70%'
			}
		},
		// 列表查询接口
		getDataList () {
			var params = {
				pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
				searchKey: this.filters.keyWord,
				// provinceCode: '440000,540000',
				multiStatus: this.filters.entyType,
				// status: this.filters.entyType,
				projectStartArea:undefined,//图审开始面积
				projectEndArea:undefined,//图审结束面积
				sortOrderMode:2,
				isAudit:0,
				// isForceOpinion:1,//是否有强应条（0：无：1：有）
				ddIdNodeBizType:100,
			}
			this.listLoading = true
			getProjectList(params).then(res => {
				var result = res.data
				if (result.success) {
					let data = result.response.data;
					
					const sortOrder = [45, 46, 52, 53, 35, 10, 0, 55]; // 你的自定义排序顺序
					
					data.sort((a, b) => {
						// 获取 ddIdProjectStatus 的索引值
						const indexA = sortOrder.indexOf(a.ddIdProjectStatus);
						const indexB = sortOrder.indexOf(b.ddIdProjectStatus);
						
						// 如果 ddIdProjectStatus 不在 sortOrder 数组中，返回它原来的顺序
						if (indexA === -1) return 1;
						if (indexB === -1) return -1;
						
						// 根据索引值比较
						return indexA - indexB;
					});
					
					console.log(data,'data22222');
					
					this.tableData = data
					
					this.pages.dataCount = result.response.dataCount
				}
				this.listLoading = false
			})
		},
		// 筛选框清空重新请求数据
		handleClear () {
			this.getDataList()
		},
		// 获取省
		getAreaList () {
			var params = {
				parentId: 1,
			}
			getAreaDictionaryList(params).then(res => {
				if (res.data.success) {
					this.areaArr = res.data.response.reverse()
				}
			})
		},
		// 查询
		getProjectList () {
			this.pages.pageIndex = 1
			this.getDataList()
		},
		// 审结操作
		conclusionProject () {
			if (this.needData) {
				this.$confirm('确定审结？', '提示', {}).then(() => {
					var params = {
						projectId: this.needData.id,
						donclusionStatus: 56
					}
					conclusionProject(params).then(res => {
						var result = res.data
						if (result.success) {
							this.$message.success(result.message)
							this.getProjectList()
						} else {
							this.$message.error(result.message)
						}
					})
				})
			} else {
				this.$message.warning('请选择需要审结项！')
			}
		},
		// 申请援助
		applyAssistance () {
			if (this.needData) {
				this.applyForSendSMSDialog = true
				this.applyForSendSMLoading = false
				this.applyRadio = ''
			} else {
				this.$message.warning('请选择需要申请援助项！')
			}
		},
		// 申请援助提交
		applyForSendSMSubmit () {
			if (!this.applyRadio) {
				this.$message.warning('请先选择申请援助原因！')
				return
			}
			var params = {
				bizId: this.needData.id,
				taskStatus: this.applyRadio
			}
			this.applyForSendSMLoading = true
			applyAssistance(params).then(res => {
				var result = res.data
				if (result.success) {
					this.$message.success(result.message)
					this.getProjectList()
					this.applyForSendSMSDialog = false
				} else {
					this.$message.error(result.message)
				}
				this.applyForSendSMLoading = false
			})
		},
		signature () {
			if (this.needData) {
				this.$confirm('确定重新发起签章？', '提示', {}).then(() => {
					let params = {
						projectId: this.needData.id,
					}
					signatureProject(params).then(res => {
						let result = res.data
						if (result.success) {
							this.$message.success(result.message)
							this.getProjectList()
						} else {
							this.$message.error(result.message)
						}
					})
				})
			} else {
				this.$message.warning('请选择需要签章项！')
			}
		},
		// 查看项目
		viewsEntyTemplate (row) {
			this.needData = row
			this.viewsApplicationDialog = true
		},
		// 项目参与人员
		viewsParticipants (row) {
			this.ParticipantsDialog = true,
					this.ParticipantsLoading = true,
					this.ParticipantsDialogName = '项目参与人员' + '（ ' + row.projectName + ' ）'
			this.ParticipantsData = []
			var params = {
				projectId: row.id
			}
			getProjectParticipants(params).then(res => {
				var result = res.data
				if (result) {
					console.log(result.response,'项目参与人员')
					this.ParticipantsData = result.response
				} else {
					this.$message.error('加载失败！')
				}
				this.ParticipantsLoading = false
			})
		},
		// 图审人员初审意见
		primaryExaminations (row) {
			this.examinationsDialog = true
			this.componentProjectId = row.id
			this.isFirst = 1 //代表初审意见
		},
		// 图审人复核审意见
		checkExaminations (row) {
			this.examinationsDialog = true
			this.componentProjectId = row.projectId
			this.isFirst = 2 //代表复审意见
		},
		// 图审人员复核意见
		primaryCheckExaminations (row) {
			this.reportORidea = false
			this.reviewReportDialog = true,
					// this.reviewTableLoading = true,
					this.reviewReportData = []
			this.needData = row
			this.preliminaryProjectId = row.id
			// var params = {
			//     projectId: row.id
			// }
			// getTaskReviewList(params).then(res => {
			//     var result = res.data
			//     if(result) {
			//         this.reviewReportData = result.response
			//     } else {
			//         this.$message.error("加载失败！")
			//     }
			//     this.reviewTableLoading = false
			// })
		},
		// 初审报告
		viewsPreliminaryReport (row) {
			this.preliminaryReportDialog = true
			this.preliminaryReportLoading = false
			this.preliminaryProjectId = row.id
		},
		// 图审报告
		viewsAuditReport (row) {
			this.dialogReportVisible = true
			this.preliminaryProjectId = row.id
		},
		//下载图纸
		downloadDrawings(value){
		
		},
		// 初审报告发起签章
		preliminaryReportSubmit () {
		
		},
		// 查看复核报告
		viewsreviewReport(row) {
			this.reportORidea = true
			this.reviewReportDialog = true,
					this.reviewTableLoading = true,
					this.needData = row
			this.preliminaryProjectId = row.id
			// var params = {
			//     projectId: row.id
			// }
			// getProjectReports(params).then(res => {
			//     var result = res.data
			//     if(result) {
			//         this.reviewReportData = result
			//     } else {
			//         this.$message.error("加载失败！")
			//     }
			//     this.reviewTableLoading = false
			// })
		},
		// 复核报告/意见的操作查看功能
		reviewReportClick(row) {
			// 报告
			if(this.reportORidea) {
				// this.viewsPreliminaryReport(this.needData)
				// 下载复核报告
				let a = document.createElement('a');
				a.href = row.finalReportPath?row.finalReportPath:row.originalReportPath
				document.body.append(a);
				a.click();
				a.remove();
			} else {
				// 意见
				this.taskId = row.id;
				this.checkExaminations(row)
			}
		},
		// 留言
		viewsLeaveMessage(row) {
			this.leaveMessageDialog = true,
					this.leaveMessageLoading = false,
					this.needData = row
			this.leaveMessageProjectName = row.projectName
			this.leaveMessageContent = ""
			var params = {
				projectId: row.id
			}
			getProjectMessageByProjectId(params).then(res => {
				var result = res.data
				if(result.success) {
					this.leaveMessageArr = result.response
				}
			})
		},
		// 留言提交
		leaveMessageSubmit() {
			if(!this.leaveMessageContent) {
				this.$message.warning("请输入留言内容！")
				return
			}
			var params = {
				parentId: 0,
				projectId: this.needData.id,
				content: this.leaveMessageContent,
			}
			this.leaveMessageLoading = true
			addProjectMessage(params).then(res => {
				var result = res.data
				if(result.success) {
					this.$message.success(result.message)
					this.leaveMessageDialog = false
				} else {
					this.$message.error(result.message)
				}
				this.leaveMessageLoading = false
			})
		},
		// 回复留言
		replyMessageFun(row) {
			this.textareaStr = "回复" + row.createUserName
			this.replyId = row.id
			this.replyPerMessageDialog = true
			this.replyPerMessageLoading = false
		},
		// 回复留言提交
		replyPerMessageSubmit() {
			if(!this.replyPerMessageContent) {
				this.$message.warning("请输入留言内容！")
				return
			}
			var params = {
				parentId: this.replyId,
				projectId: this.needData.id,
				content: this.replyPerMessageContent,
			}
			this.replyPerMessageLoading = true
			addProjectMessage(params).then(res => {
				var result = res.data
				if(result.success) {
					this.$message.success(result.message)
					this.replyPerMessageDialog = false
					var params = {
						projectId: this.needData.id
					}
					getProjectMessageByProjectId(params).then(res => {
						var result = res.data
						if(result.success) {
							this.leaveMessageArr = result.response
						}
					})
				} else {
					this.$message.error(result.message)
				}
				this.replyPerMessageLoading = false
			})
		},
		// 获取项目审结期限倒计时天数
		getProjectDayFun() {
			var params = {
			
			}
			getProjectConclusionExpire(params).then((res) => {
				var result = res.data
				if(result.response && result.response.length > 0) {
					this.isShowReminder = true
					this.projectList = result.response
				} else {
					this.isShowReminder = false
				}
			})
		},
		// 最小化亮灯提醒
		minimizeReminder() {
			this.isSpecialReminder = false
			this.isShowReminder = true
		},
		// 最大化亮灯提醒
		maxsizeReminder() {
			this.isSpecialReminder = true
			this.isShowReminder = true
		},
		// 点击table某一行
		currentChange(val) {
			if(val)
			{
				this.needData = val
				console.log(val,'val')
			}
		},
		// 分页
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getDataList();
		},
		handleSizeChange(val){
			this.pages.pageSize = val;
			this.getDataList();
		},
		sendCrowdPageChange(val) {
			this.sendCrowdPage.DataCount = val
		},
		// 时间格式化
		formatCreateTime: function(row, column) {
			return !row || row == ""
					? ""
					: util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
		},
		// 表格数据分页条数累计
		indexMethod(index) {
			var currentPage = this.pages.pageIndex
			var pageSize = this.pages.pageSize
			return (index + 1) + (currentPage - 1) * pageSize
		},
		// 列表样式
		rowClass(){
			return 'padding:8px!important;'
		},
		
		
		// 编辑项目
		handleEdit() {
			if(this.needData) {
				let params = {
					projectId: this.needData.id
				}
				getProjectApplicationByProjectId(params).then(res=>{
					if (res.data.success){
						this.editEntryForm = {
							remark: this.needData.projectNotes,
							customReportNo: this.needData.customReportNo,
							customFailReportNo: this.needData.customFailReportNo,
							address: res.data.response.address,
							contractor: res.data.response.contractor,
							id: res.data.response.id,
						}
						this.addNewEntryDialog = true
						this.addNewEntryLoading = false
					}
				})
				
			} else {
				this.$message.warning("请选择需要修改项！")
			}
		},
		// 编辑项目提交
		editEntrySubmit() {
			this.$refs.addNewEntryRef.validate((valid) => {
				if (valid) {
					this.addNewEntryLoading = true;
					let params = {
						...this.editEntryForm,
					}
					params.projectNotes = this.editEntryForm.remark
					params.id=this.needData.id
							updateProjectInfo(params).then(res => {
								this.addNewEntryLoading = false;
								this.addNewEntryDialog = false;
								let result = res.data
								if(result.success) {
									this.$message.success(result.message)
									this.getDataList();
								} else {
									this.$message.warning(result.message)
								}
							})
				}
				let obj = {
					"id":this.editEntryForm.id,
					"projectId": this.needData.id,
					"address": this.editEntryForm.address,
					"approvalFile": null,
					"contacts": null,
					"contractor": this.editEntryForm.contractor,
					"ddIdCategory": null,
					"decorationArea": null,
					"decorationFloor": null,
					"decorationPositions": [],
					"fireFacilitiess": [],
					"isChangePurpose": false,
					"isDecoration": false,
					"isKeepWarm": false,
					"isSpecialFire": null,
					"isStrengthenFire": null,
					"keepWarmFloor": null,
					"keepWarmMaterial": null,
					"keepWarmPosition": null,
					"licenseFile": null,
					"materialTypes": [],
					"notes": null,
					"originalUse": null,
					"phone": null,
					"specialSituations": [],
					"totalArea": null,
					"totalInvestment": null,
					"useCharacter": null,
					"buildReqs": [
						{
							"buildName": "",
							"structureType": "",
							"useCharacter": "",
							"fireRating": "",
							"overgroundFloors": "",
							"undergroundFloors": "",
							"height": "",
							"length": "",
							"landArea": "",
							"overgroundArea": "",
							"undergroundArea": ""
						}
					],
					"companyReqs": [
						{
							"companyTypeName": "",
							"companyName": "",
							"qualificationLevel": "",
							"corporateCard": "",
							"projectDirectorCard": "",
							"phone": ""
						}
					]
				}
				updateProjectApplication(obj).then(res=>{})
			})
		},
		// 编辑项目系数
		handleEditContact () {
			if (this.needData) {
				this.editProjectContactDialog = true
				this.addNewEntryLoading = false
				this.editContactEntryForm = {
					coefficient: this.needData.coefficient,
				}
			} else {
				this.$message.warning("请输入难度系数！")
			}
		},
		// 编辑项目系数提交
		editContactEntrySubmit () {
			this.$refs.addNewContactEntryRef.validate((valid) => {
				if (valid) {
					this.addNewEntryLoading = true;
					var params = {
						coefficient: this.editContactEntryForm.coefficient,
						id: this.needData.id,
					}
					updateProjectInfo(params).then(res => {
						this.addNewEntryLoading = false;
						this.editProjectContactDialog = false;
						var result = res.data
						if (result.success) {
							this.$message.success(result.message)
							this.getDataList();
						} else {
							this.$message.warning(result.message)
						}
					})
				}
			})
		},
		// 重新审图
		resetDrawingReview(){
			if(this.needData) {
				this.resetDrawingReviewDialog = true
				this.resetDrawingReviewLoading = false
				
				this.resetDrawingReviewForm = {
					projectName: this.needData.projectName,
					overdueDate: "",
					
				}
			} else {
				this.$message.warning("请选择需要修改项！")
			}
		},
		// 项目阶段明细
		viewStageList(row) {
			this.stageDialog = true,
					this.stageLoading = true,
					this.stageData = []
			let obj = {
				provinceCode:row.provinceCode,
			}
			aiDoLogin(obj).then(res=>{
				console.log(res)
				if (res.data.success){
					const aiToken = res.data.response.token;
					const domain = res.data.response.domain
					// 设置自定义请求头
					const headers = {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${aiToken}`,// 添加你的授权信息
						'accept':'application/json',
						'X-Tuzhi-User':'qingda',
						'X-Tuzhi-JWT':`Bearer ${aiToken}`,
						'X-Tuzhi-ProjectId':this.needData.id
					};
					console.log(aiToken,'this.aitoken')
					// 创建一个新的axios实例
					const axiosInstance = axios.create();
					// // 要发送的数据
					// const postData = {
					// 	project_id : this.needData.id,
					// };
					// 发送 POST 请求
					axiosInstance.get(domain+`/project/${this.needData.id}/progress`, { headers })
							.then(response => {
								// 请求成功，处理返回的数据
								console.log(response.data,'项目进度数据');
								if (response.data){
									const right = response.data.filter(item=>item.state === 'SUCCESS')
									if (right){
										console.log(parseFloat((right.length/response.data.length).toFixed(2))*100+'%',11111)
										this.parseNum = parseFloat((right.length/response.data.length).toFixed(2))*100+'%'
									}
									
								}
								
							})
							.catch(error => {
								// 请求失败，处理错误
								console.error('Error:', error);
							});
				}
			})
			let params = {
				projectId: row.id
			}
			getStageListByProjectId(params).then(res => {
				var result = res.data
				if(result) {
					this.stageData = result.response;
					// console.log(this.stageData);
				} else {
					this.$message.error("加载失败！")
				}
				this.stageLoading = false
			})
			
		},
		// 查看意见
		checkOpinonView(
				row
		) {
			console.log(row,'rowaaa')
			this.showOpinionDialog = true
			this.showOpinionProjectId =this.needData.id
			this.aiDomainUrl = row.aiDomainUrl+'/project/qingda/'+row.aiTaskId+'/'+this.needData.projectName
			this.showOpinionFlowNodeId = row.assignOpinionNodeId
			
		},
		// 查看报告
		showReportFile(file) {
			let a = document.createElement('a');
			a.href = file
			a.target = 'blank';
			document.body.append(a);
			a.click();
			a.remove();
		},
		colsedProjectDelay(){
			this.projectDelayDialog = false
			
		},
		handleDelay(val){
			this.needdDataDelay = val
			console.log(val,'valDelay')
		},
		//项目延期
		projectDelay () {
			if (this.needData === null || !this.needData.id) {
				this.$message({
					message: '请选择一条数据',
					type: "error"
				});
				return
			}
			this.projectDelayDialog = true,
					this.ParticipantsLoading = true
			this.ParticipantsData = []
			this.ParticipantsDialogName = '项目参与人员:' + '（ ' + this.needData.projectName + ' ）'
			var params = {
				projectId: this.needData.id,
			}
			getProjectParticipants(params).then(res => {
				var result = res.data
				if (result) {
					this.ParticipantsData = result.response;
				} else {
					this.$message.error("加载失败！")
				}
				this.ParticipantsLoading = false
			})
		},
		//提交项目延期
		addProjectDelay(){
			
			this.addNewEntryLoading = true;
			let params = {
				// overdueDate:this.projectDelayContent,//过期时间
				overdueDate:this.needdDataDelay.overdueDate,//过期时间
				taskId:this.needdDataDelay.taskId,//任务ID
			}
			changeTaskOverdueDate(params).then(res => {
				const result = res.data
				if (result.success) {
					this.$message.success(result.message)
					this.getDataList();
					// this.colsedProjectDelay()
				} else {
					this.$message.warning(result.message)
				}
			})
		},
		
		viewsProjectStageUserInfo(projectId,row) {
			this.ParticipantsDialog = true;
			this.ParticipantsLoading = true;
			this.ParticipantsDialogName =  "项目参与人员";
			this.ParticipantsData = []
			
			
			
			var flowNodeId = row.assignUploadNodeId
			if(row.assignOpinionNodeId) flowNodeId += ","+row.assignOpinionNodeId
			if(row.assignOpinionSummaryNodeId) flowNodeId += ","+row.assignOpinionSummaryNodeId
			
			var params = {
				projectId: projectId,
				nodeIds: flowNodeId
			}
			getProjectParticipants(params).then(res => {
				var result = res.data
				if(result) {
					this.ParticipantsData = result.response.filter(item=> item.participationTypeName !== '见习工程师');
				} else {
					this.$message.error("加载失败！")
				}
				this.ParticipantsLoading = false
			})
		},
		// 查看意见
		showProjectFile(projectId,flowNodeId) {
			
			this.projectFileDialog = true
			this.projectFileLoading = true
			this.projectFileData = []
			
			var params = {
				projectId: projectId,
				flowNodeId: flowNodeId
			}
			getProjectFileInfoByFlowNodeId(params).then(res => {
				let result = res.data
				if(result.success) {
					this.projectFileData = result.response.files
					this.projectFileLoading = false
				}
			})
			
		},
		changeFreezeStatus(){
			if(this.needData) {
				this.freezeStatusDialog = true
				this.freezeStatusLoading = false
				
				this.freezeStatusForm.freezeStatus = this.needData.ddIdConclusionStatus == 0 ? 57 : 0
			} else {
				this.$message.warning("请选择需要修改项！")
			}
		},
		freezeStatusSubmit(){
			this.$refs.freezeStatusRef.validate((valid) => {
				if (valid) {
					this.freezeStatuswLoading = true;
					var params = {
						id: this.needData.id,
						ddIdConclusionStatus:this.freezeStatusForm.freezeStatus,
					}
					updateProjectInfo(params).then(res => {
						this.freezeStatusLoading = false;
						this.freezeStatusDialog = false;
						var result = res.data
						if(result.success) {
							this.$message.success(result.message)
							this.getDataList();
						} else {
							this.$message.warning(result.message)
						}
					})
				}
			})
		},
		// 修改技术部专家
		changeTechExpert(){
			if(this.needData) {
				this.techExpertDialog = true
				this.techExpertLoading = false
				this.techExpertForm.assignedUserId = undefined;
			} else {
				this.$message.warning("请选择需要修改项！")
			}
		},
		techExpertSubmit(){
			this.$refs.techExpertRef.validate((valid) => {
				if (valid) {
					if(this.techExpertForm.assignedUserId == undefined)
					{
						this.$message.warning("请选择技术专家！")
						return;
					}
					this.techExpertLoading = true;
					var params = {
						projectId: this.needData.id,
						assignedUserId:this.techExpertForm.assignedUserId,
					}
					changeAssignedUserIdByTechExpert(params).then(res => {
						this.techExpertLoading = false;
						this.techExpertDialog = false;
						this.techExpertForm.assignedUserId = undefined;
						var result = res.data
						if(result.success) {
							this.$message.success(result.message)
							this.getDataList();
						} else {
							this.$message.warning(result.message)
						}
					})
				}
			})
		},
		resetDrawingReviewSubmit(){
			this.$refs.resetDrawingReviewRef.validate((valid) => {
				if (valid) {
					this.resetDrawingReviewLoading = true;
					var params = {
						projectId: this.needData.id,
						overdueDate:this.resetDrawingReviewForm.overdueDate,
					}
					resetDrawingReviewApi(params).then(res => {
						this.resetDrawingReviewLoading = false;
						this.resetDrawingReviewDialog = false;
						var result = res.data
						if(result.success) {
							this.$message.success(result.message)
							this.getDataList();
						} else {
							this.$message.warning(result.message)
						}
					})
				}
			})
		}
	},
	watch: {
	
	},
	created(){
		this.utils = util
		let routers = window.localStorage.router
				? JSON.parse(window.localStorage.router)
				: [];
		this.buttonList = getButtonList(this.$route.path, routers);
		this.getDataList();
		this.getAreaList();
		this.getNamespaceArr()
		this.getProjectDayFun();
	}
}
</script>

<style lang="stylus" scoped>
.toolbar-container {
	display: flex; /* 使用Flex布局 */
	justify-content: space-between; /* 控制内部元素在水平方向上的分布，将它们分散排列 */
	align-items: center; /* 控制内部元素在垂直方向上的对齐方式，可以根据需要调整 */
}
.projectName {
	text-align: center;
}
.projectSelect {
	margin-bottom: 15px;
}
.projectTitle {
	margin-bottom: 15px
}
.replyBtn {
	margin: 10px 0;
	color: #409eff;
	cursor: pointer;
	width: 30px;
}
.replyMessage {
	background: #eee;
	line-height: 30px;
	padding: 5px;
	border-radius: 2px;
	margin: 5px 15px;
}
.titleTimer {
	margin-left: 10px;
}
.specialReminder {
	width: 350px;
	height: 200px;
	background: #fff;
	border-radius: 5px;
	position: fixed;
	right: 7px;
	bottom: 7px;
	z-index: 10;
	border: 1px solid #999;
	overflow: hidden;
}
.reminderBtn {
	display: flex;
	justify-content: flex-end;
	margin-right: 10px;
}
.projectPra {

}
.projectBox {
	width: 90%;
	margin: 0 auto 0;
	height: 150px;
	// display: flex;
	// flex-direction: column;
	// justify-content: center;
	overflow-y: auto;
}
.projectName {
	color: red;
	font-size: 14px;
}
.projectDay {
	color: red;
	font-size: 16px;
	margin-left: 10px;
}
.projectList {
	margin-top: 10px;
	font-size: 14px;
}
.miniBox {
	width: 45px;
	height: 45px;
	position: fixed;
	right: 7px;
	bottom: 7px;
	border-radius: 5px 5px 0 0;
	border-left: 2px solid #999;
	border-top: 2px solid #999;
	border-right: 2px solid #999;
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.miniNodes {
	width: 25px;
	height: 25px;
	right: 7px;
	bottom: 7px;
	border-radius: 5px;
	border: 2px solid #999;
	z-index: 10;
}
.viewProject>>>.el-dialog__body {
	padding: 0px 20px 30px;
	margin-top: -15px;
}
</style>
